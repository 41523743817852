import React, { PropsWithChildren, PureComponent } from 'react';

import ReactCSSTransitionReplace from 'react-css-transition-replace';

import styled from 'styled-components';

import Button from '../atoms/Button/Button';
import Loader from '../atoms/Loader/Loader';

import { Color } from '@shared/enums/button-color';
import { Size } from '@shared/enums/button-size';
import { Status } from '@shared/markup/constants';

const ButtonExtend = styled(Button)<{ status: Status }>`
  background-color: ${props =>
    props.status === Status.Loading && 'transparent'};
  transition-property: width, background-color;
  transition-duration: 0.5s;
  transition-timing-function: linear;

  .fade-wait-leave {
    opacity: 1;
  }

  .fade-wait-leave.fade-wait-leave-active {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .fade-wait-enter {
    opacity: 0;
  }

  .fade-wait-enter.fade-wait-enter-active {
    opacity: 1;
    transition: opacity 0.4s ease;
  }
`;

const Wrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

type StatusButtonProps = {
  className?: string;
  colorType?: Color;
  sizeType?: Size;
  size?: number;
  onClick?: () => void;
  status?: Status;
  disabled?: boolean;
  loaderSize?: number;
  type?: any;
};

export default class StatusButton extends PureComponent<
  PropsWithChildren<StatusButtonProps>
> {
  static defaultProps = {
    status: Status.Nothing,
    loaderSize: 2.625,
  };

  state = {
    status: this.props.status,
  };

  loading() {
    this.setState({
      status: Status.Loading,
    });
  }

  nothing() {
    this.setState({
      status: Status.Nothing,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === this.props.status) {
      return;
    }
    switch (nextProps.status) {
      case Status.Loading:
        this.loading();
        break;
      case Status.Nothing:
        this.nothing();
        break;
    }
  }

  render() {
    const { children, loaderSize, ...props } = this.props;

    return (
      <ButtonExtend status={this.state.status} {...props}>
        <ReactCSSTransitionReplace
          transitionName="fade-wait"
          transitionEnterTimeout={400}
          transitionLeaveTimeout={300}
        >
          {this.state.status === Status.Loading ? (
            <Loader key="key1" size={loaderSize} />
          ) : (
            <Wrapper key="key2">
              <span>{children}</span>
            </Wrapper>
          )}
        </ReactCSSTransitionReplace>
      </ButtonExtend>
    );
  }
}
