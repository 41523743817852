const moment = require('moment/min/moment-with-locales');
import { DATE_FORMAT } from '@shared/markup/constants';
import { i18n } from 'i18n';

export const localizedMoment = (date: string | Date, format?: string) => {
  const lang = i18n.language === 'zh' ? 'zh_cn' : i18n.language;

  moment.locale(lang);

  return moment(date).format(format ?? DATE_FORMAT);
};
