import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';

import { AppState } from '@redux/types';
import { _getPurchasedSubscriptions } from '@redux/reducers/profile/selectors';
import { compose } from 'redux';
import { hideChangePurchasedSubscriptionPopup } from '@redux/reducers/popups/action';
import { loadApplications } from '@redux/reducers/applications/action';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import Loader from '../atoms/Loader/Loader';
import Text from '../atoms/Text/Text';

import { getUrl } from '@utils/origin';
import { localizedMoment } from '@utils/localizedMoment';
import redirect from '@utils/redirect';

import { Align } from '@shared/enums/align';
import { AnimationType } from '@shared/enums/animation-type';
import Api from '../../api';
import BottomMessage from './BottomMessage';
import { Color } from '@shared/enums/button-color';
import { DATE_FORMAT } from '@shared/markup/constants';
import { Size } from '@shared/enums/button-size';
import moment from 'moment';
import { withTranslation } from 'i18n';

type ChangePurchasedSubscriptionPopupProps = {
  isOpen?: boolean;
  canChange?: boolean;
  dispatch?: (data: any) => void;
  expireDate?: string | Date;
  t?: (key: string, options?: Record<string, string>) => string;
};

class ChangePurchasedSubscriptionPopup extends Component<ChangePurchasedSubscriptionPopupProps> {
  state = {
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    !prevProps.isOpen &&
      this.props.isOpen &&
      this.setState({ isLoading: false });
  }

  handleClose = () => {
    this.props.dispatch(hideChangePurchasedSubscriptionPopup());
  };

  getText = () => {
    const { t, canChange, expireDate } = this.props;

    const text = canChange ? 'modifySub' : 'anotherSub';

    const renewalDate =
      canChange && expireDate ? localizedMoment(expireDate, DATE_FORMAT) : null;

    return (
      <Text color="middleGrey" lineHeight={1.4}>
        {t(text, { renewalDate })}
      </Text>
    );
  };

  getButton = () => {
    const { t, canChange } = this.props;

    const { isLoading } = this.state;

    if (isLoading) return <Loader />;

    const contactBtn = (
      <Button
        colorType={Color.FullBlue}
        sizeType={Size.Big}
        href={getUrl('https://3d4medical.com/contact?app=complete-anatomy')}
        component="a"
      >
        <Text color="white" size={0.875} lineHeight={1.4}>
          {t('contactUs')}
        </Text>
      </Button>
    );

    const changeBtn = (
      <Button
        colorType={Color.FullBlue}
        sizeType={Size.Big}
        onClick={this.switchLicense}
      >
        <Text color="white" size={0.875} lineHeight={1.4}>
          {t('confirm')}
        </Text>
      </Button>
    );

    return canChange ? changeBtn : contactBtn;
  };

  switchLicense = async () => {
    this.setState({ isLoading: true });
    const status = await Api.switchLicense();

    if (status === 200) {
      this.props.dispatch(loadApplications());
      this.handleClose();
    }

    redirect();
  };

  render() {
    return (
      <BottomMessage
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
        animationType={AnimationType.SlideExtend}
      >
        <Box pl={1.5} pr={1.5} pt={1.5} pb={0.875}>
          <Box maxWidth={18} align={Align.Center}>
            <Box align={Align.Center} mb={2}>
              {this.getText()}
            </Box>
            {this.getButton()}
          </Box>
        </Box>
      </BottomMessage>
    );
  }
}

const withConnect = connect((state: AppState) => {
  const expireDate = _getPurchasedSubscriptions(state)
    .map(s => s.subscription.expire)
    .join('');

  return {
    isOpen: state.popups.changePurchasedSubscription.isOpen,
    expireDate,
  };
});

export default compose<ComponentType<ChangePurchasedSubscriptionPopupProps>>(
  withConnect,
  withTranslation('common')
)(ChangePurchasedSubscriptionPopup);
