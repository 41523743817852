import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { Colors } from '@shared/interfaces/themes';
import { getStyle } from '@shared/markup/functions';
import { theme } from 'theme';

const Svg = styled.svg`
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  width: ${props => props.width}em;
  height: ${props => props.height}em;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: ${({ color }) => theme.colors[color]};

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
`;

type LoaderProps = {
  className?: string;
  size?: number;
  grow?: boolean;
  fullHeight?: boolean;
  color?: keyof Colors | string;
};

class Loader extends PureComponent<LoaderProps> {
  static defaultProps = {
    color: 'turquoise',
  };
  render() {
    return (
      <div role="status" aria-busy="true" className={this.props.className}>
        <Svg viewBox="25 25 50 50">
          <Circle
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="3"
            strokeMiterlimit="10"
            color={this.props.color}
          />
        </Svg>
      </div>
    );
  }
}

const styledLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ grow }) => grow && `flex-grow: 1;`}
  ${({ fullHeight }) => fullHeight && `height: 100%; flex-grow: 1;`}
  
  ${Svg} {
    ${props => getStyle(props.size || 2, 'width', 'em')}
    ${props => getStyle(props.size || 2, 'height', 'em')}
  }
`;

export default styledLoader;
